@import url(https://fonts.googleapis.com/css2?family=Saira:wght@600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Saira:wght@600&display=swap);
div.AdamosSignIn-root{display:-webkit-flex;display:flex;-webkit-justify-content:space-around;justify-content:space-around;width:100%}div.AdamosSignIn-root button.adamosSignIn{margin-top:20px;width:80%;border-radius:5px;padding:5px;background:#fff;color:#008dab;box-shadow:0px 3px 8px 2px rgba(0,0,0,.2)}div.AdamosSignIn-root button.adamosSignIn:hover{background:#008dab;color:#fff}div.AdamosSignIn-root button.adamosSignIn:hover div.loginIcon{background-image:url(/static/media/loginWhite.82a7e8a9.svg) !important}div.AdamosSignIn-root button.adamosSignIn .MuiButton-label{display:-webkit-flex;display:flex;-webkit-align-items:center;align-items:center;-webkit-justify-content:space-between;justify-content:space-between}div.AdamosSignIn-root button.adamosSignIn .MuiButton-label .adamosLogo{width:40px;height:40px}div.AdamosSignIn-root button.adamosSignIn .MuiButton-label .labelText{text-transform:none;padding-right:25px;font-family:"Saira",sans-serif;font-weight:600;font-size:14px}div.AdamosSignIn-root button.adamosSignIn .MuiButton-label div.loginIcon{width:20px;height:18px;margin-right:5px;background-image:url(/static/media/loginBlue.e005937c.svg);background-size:contain;background-repeat:no-repeat}
div.AzureADSignIn-root{display:-webkit-flex;display:flex;-webkit-justify-content:space-around;justify-content:space-around;width:100%;padding-top:5%}div.AzureADSignIn-root button.azureADSignIn{width:80%;background:#fff;color:#5e5e5e;font-weight:600;padding-right:12px;min-height:41px;border-radius:unset;border:1px solid #5e5e5e;padding:unset}div.AzureADSignIn-root button.azureADSignIn:hover{background:#2f2f2f;color:#fff}div.AzureADSignIn-root button.azureADSignIn .MuiButton-label{display:-webkit-flex;display:flex;-webkit-align-items:center;align-items:center;-webkit-justify-content:space-between;justify-content:space-between}div.AzureADSignIn-root button.azureADSignIn .MuiButton-label .azureADLogo{padding-left:12px;padding-right:12px}div.AzureADSignIn-root button.azureADSignIn .MuiButton-label .labelText{text-transform:none;padding-right:25px;font-family:"Segoe UI Regular",sans-serif;font-weight:600;font-size:15px}div.AzureADSignIn-root button.azureADSignIn .MuiButton-label div.loginIcon{width:20px;height:18px;margin-right:5px;background-size:contain;background-repeat:no-repeat}
.uppy-Informer {
    position: absolute;
    bottom: 60px;
    left: 0;
    right: 0;
    text-align: center;
    opacity: 1;
    -webkit-transform: none;
            transform: none;
    transition: all 250ms ease-in;
    z-index: 1005;
}

.uppy-Informer[aria-hidden=true] {
    opacity: 0;
    -webkit-transform: translateY(350%);
            transform: translateY(350%);
    transition: all 300ms ease-in;
    z-index: -1000;
}

.uppy-Informer p {
    display: inline-block;
    margin: 0;
    padding: 0;
    font-size: 12px;
    line-height: 1.4;
    font-weight: 400;
    padding: 6px 15px;
    background-color: #757575;
    /* no !important */
    color: #fff;
    border-radius: 18px;
    max-width: 90%;
}

.uppy-size--md .uppy-Informer p {
    font-size: 14px;
    line-height: 1.3;
    max-width: 500px;
    padding: 10px 20px;
}

.uppy-Informer span {
    line-height: 12px;
    width: 13px;
    height: 13px;
    display: inline-block;
    vertical-align: middle;
    color: #525252;
    background-color: #fff;
    border-radius: 50%;
    position: relative;
    top: -1px;
    left: 3px;
    font-size: 10px;
    margin-left: -1px;
}

.uppy-Informer span:hover {
    cursor: help;
}

.uppy-Informer span:after {
    line-height: 1.3;
    word-wrap: break-word;
}


/* -------------------------------------------------------------------
    Microtip
  
    Modern, lightweight css-only tooltips
    Just 1kb minified and gzipped
  
    @author Ghosh
    @package Microtip
  
  ----------------------------------------------------------------------
    1. Base Styles
    2. Direction Modifiers
    3. Position Modifiers
    4. Custom Styles
  --------------------------------------------------------------------*/


/* ------------------------------------------------
    [1] Base Styles
  -------------------------------------------------*/

.uppy-Root [aria-label][role~="tooltip"] {
    /* no important */
    position: relative;
}

.uppy-Root [aria-label][role~="tooltip"]::before,
.uppy-Root [aria-label][role~="tooltip"]::after {
    /* no important */
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    will-change: transform;
    opacity: 0;
    pointer-events: none;
    transition: all 0.18s ease-in-out 0s;
    transition: all var(--microtip-transition-duration, 0.18s) var(--microtip-transition-easing, ease-in-out) var(--microtip-transition-delay, 0s);
    position: absolute;
    box-sizing: border-box;
    z-index: 10;
    -webkit-transform-origin: top;
            transform-origin: top;
}

.uppy-Root [aria-label][role~="tooltip"]::before {
    /* no important */
    background-size: 100% auto !important;
    content: "";
}

.uppy-Root [aria-label][role~="tooltip"]::after {
    /* no important */
    background: rgba(17, 17, 17, 0.9);
    border-radius: 4px;
    color: #ffffff;
    content: attr(aria-label);
    font-size: 13px;
    font-size: var(--microtip-font-size, 13px);
    font-weight: normal;
    font-weight: var(--microtip-font-weight, normal);
    text-transform: none;
    text-transform: var(--microtip-text-transform, none);
    padding: .5em 1em;
    white-space: nowrap;
    box-sizing: content-box;
}

.uppy-Root [aria-label][role~="tooltip"]:hover::before,
.uppy-Root [aria-label][role~="tooltip"]:hover::after,
.uppy-Root [aria-label][role~="tooltip"]:focus::before,
.uppy-Root [aria-label][role~="tooltip"]:focus::after {
    /* no important */
    opacity: 1;
    pointer-events: auto;
}


/* ------------------------------------------------
    [2] Position Modifiers
  -------------------------------------------------*/

.uppy-Root [role~="tooltip"][data-microtip-position|="top"]::before {
    /* no important */
    background: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2236px%22%20height%3D%2212px%22%3E%3Cpath%20fill%3D%22rgba%2817,%2017,%2017,%200.9%29%22%20transform%3D%22rotate%280%29%22%20d%3D%22M2.658,0.000%20C-13.615,0.000%2050.938,0.000%2034.662,0.000%20C28.662,0.000%2023.035,12.002%2018.660,12.002%20C14.285,12.002%208.594,0.000%202.658,0.000%20Z%22/%3E%3C/svg%3E") no-repeat;
    height: 6px;
    width: 18px;
    margin-bottom: 5px;
}

.uppy-Root [role~="tooltip"][data-microtip-position|="top"]::after {
    /* no important */
    margin-bottom: 11px;
}

.uppy-Root [role~="tooltip"][data-microtip-position|="top"]::before {
    /* no important */
    -webkit-transform: translate3d(-50%, 0, 0);
            transform: translate3d(-50%, 0, 0);
    bottom: 100%;
    left: 50%;
}

.uppy-Root [role~="tooltip"][data-microtip-position|="top"]:hover::before {
    /* no important */
    -webkit-transform: translate3d(-50%, -5px, 0);
            transform: translate3d(-50%, -5px, 0);
}

.uppy-Root [role~="tooltip"][data-microtip-position|="top"]::after {
    /* no important */
    -webkit-transform: translate3d(-50%, 0, 0);
            transform: translate3d(-50%, 0, 0);
    bottom: 100%;
    left: 50%;
}

.uppy-Root [role~="tooltip"][data-microtip-position="top"]:hover::after {
    /* no important */
    -webkit-transform: translate3d(-50%, -5px, 0);
            transform: translate3d(-50%, -5px, 0);
}


/* ------------------------------------------------
    [2.1] Top Left
  -------------------------------------------------*/

.uppy-Root [role~="tooltip"][data-microtip-position="top-left"]::after {
    /* no important */
    -webkit-transform: translate3d(calc(-100% + 16px), 0, 0);
            transform: translate3d(calc(-100% + 16px), 0, 0);
    bottom: 100%;
}

.uppy-Root [role~="tooltip"][data-microtip-position="top-left"]:hover::after {
    /* no important */
    -webkit-transform: translate3d(calc(-100% + 16px), -5px, 0);
            transform: translate3d(calc(-100% + 16px), -5px, 0);
}


/* ------------------------------------------------
    [2.2] Top Right
  -------------------------------------------------*/

.uppy-Root [role~="tooltip"][data-microtip-position="top-right"]::after {
    /* no important */
    -webkit-transform: translate3d(calc(0% + -16px), 0, 0);
            transform: translate3d(calc(0% + -16px), 0, 0);
    bottom: 100%;
}

.uppy-Root [role~="tooltip"][data-microtip-position="top-right"]:hover::after {
    /* no important */
    -webkit-transform: translate3d(calc(0% + -16px), -5px, 0);
            transform: translate3d(calc(0% + -16px), -5px, 0);
}


/* ------------------------------------------------
    [2.3] Bottom
  -------------------------------------------------*/

.uppy-Root [role~="tooltip"][data-microtip-position|="bottom"]::before {
    /* no important */
    background: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2236px%22%20height%3D%2212px%22%3E%3Cpath%20fill%3D%22rgba%2817,%2017,%2017,%200.9%29%22%20transform%3D%22rotate%28180%2018%206%29%22%20d%3D%22M2.658,0.000%20C-13.615,0.000%2050.938,0.000%2034.662,0.000%20C28.662,0.000%2023.035,12.002%2018.660,12.002%20C14.285,12.002%208.594,0.000%202.658,0.000%20Z%22/%3E%3C/svg%3E") no-repeat;
    height: 6px;
    width: 18px;
    margin-top: 5px;
    margin-bottom: 0;
}

.uppy-Root [role~="tooltip"][data-microtip-position|="bottom"]::after {
    /* no important */
    margin-top: 11px;
}

.uppy-Root [role~="tooltip"][data-microtip-position|="bottom"]::before {
    /* no important */
    -webkit-transform: translate3d(-50%, -10px, 0);
            transform: translate3d(-50%, -10px, 0);
    bottom: auto;
    left: 50%;
    top: 100%;
}

.uppy-Root [role~="tooltip"][data-microtip-position|="bottom"]:hover::before {
    /* no important */
    -webkit-transform: translate3d(-50%, 0, 0);
            transform: translate3d(-50%, 0, 0);
}

.uppy-Root [role~="tooltip"][data-microtip-position|="bottom"]::after {
    /* no important */
    -webkit-transform: translate3d(-50%, -10px, 0);
            transform: translate3d(-50%, -10px, 0);
    top: 100%;
    left: 50%;
}

.uppy-Root [role~="tooltip"][data-microtip-position="bottom"]:hover::after {
    /* no important */
    -webkit-transform: translate3d(-50%, 0, 0);
            transform: translate3d(-50%, 0, 0);
}


/* ------------------------------------------------
    [2.4] Bottom Left
  -------------------------------------------------*/

.uppy-Root [role~="tooltip"][data-microtip-position="bottom-left"]::after {
    /* no important */
    -webkit-transform: translate3d(calc(-100% + 16px), -10px, 0);
            transform: translate3d(calc(-100% + 16px), -10px, 0);
    top: 100%;
}

.uppy-Root [role~="tooltip"][data-microtip-position="bottom-left"]:hover::after {
    /* no important */
    -webkit-transform: translate3d(calc(-100% + 16px), 0, 0);
            transform: translate3d(calc(-100% + 16px), 0, 0);
}


/* ------------------------------------------------
    [2.5] Bottom Right
  -------------------------------------------------*/

.uppy-Root [role~="tooltip"][data-microtip-position="bottom-right"]::after {
    /* no important */
    -webkit-transform: translate3d(calc(0% + -16px), -10px, 0);
            transform: translate3d(calc(0% + -16px), -10px, 0);
    top: 100%;
}

.uppy-Root [role~="tooltip"][data-microtip-position="bottom-right"]:hover::after {
    /* no important */
    -webkit-transform: translate3d(calc(0% + -16px), 0, 0);
            transform: translate3d(calc(0% + -16px), 0, 0);
}


/* ------------------------------------------------
    [2.6] Left
  -------------------------------------------------*/

.uppy-Root [role~="tooltip"][data-microtip-position="left"]::before,
.uppy-Root [role~="tooltip"][data-microtip-position="left"]::after {
    /* no important */
    bottom: auto;
    left: auto;
    right: 100%;
    top: 50%;
    -webkit-transform: translate3d(10px, -50%, 0);
            transform: translate3d(10px, -50%, 0);
}

.uppy-Root [role~="tooltip"][data-microtip-position="left"]::before {
    /* no important */
    background: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2212px%22%20height%3D%2236px%22%3E%3Cpath%20fill%3D%22rgba%2817,%2017,%2017,%200.9%29%22%20transform%3D%22rotate%28-90%2018%2018%29%22%20d%3D%22M2.658,0.000%20C-13.615,0.000%2050.938,0.000%2034.662,0.000%20C28.662,0.000%2023.035,12.002%2018.660,12.002%20C14.285,12.002%208.594,0.000%202.658,0.000%20Z%22/%3E%3C/svg%3E") no-repeat;
    height: 18px;
    width: 6px;
    margin-right: 5px;
    margin-bottom: 0;
}

.uppy-Root [role~="tooltip"][data-microtip-position="left"]::after {
    /* no important */
    margin-right: 11px;
}

.uppy-Root [role~="tooltip"][data-microtip-position="left"]:hover::before,
.uppy-Root [role~="tooltip"][data-microtip-position="left"]:hover::after {
    /* no important */
    -webkit-transform: translate3d(0, -50%, 0);
            transform: translate3d(0, -50%, 0);
}


/* ------------------------------------------------
    [2.7] Right
  -------------------------------------------------*/

.uppy-Root [role~="tooltip"][data-microtip-position="right"]::before,
.uppy-Root [role~="tooltip"][data-microtip-position="right"]::after {
    /* no important */
    bottom: auto;
    left: 100%;
    top: 50%;
    -webkit-transform: translate3d(-10px, -50%, 0);
            transform: translate3d(-10px, -50%, 0);
}

.uppy-Root [role~="tooltip"][data-microtip-position="right"]::before {
    /* no important */
    background: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2212px%22%20height%3D%2236px%22%3E%3Cpath%20fill%3D%22rgba%2817,%2017,%2017,%200.9%29%22%20transform%3D%22rotate%2890%206%206%29%22%20d%3D%22M2.658,0.000%20C-13.615,0.000%2050.938,0.000%2034.662,0.000%20C28.662,0.000%2023.035,12.002%2018.660,12.002%20C14.285,12.002%208.594,0.000%202.658,0.000%20Z%22/%3E%3C/svg%3E") no-repeat;
    height: 18px;
    width: 6px;
    margin-bottom: 0;
    margin-left: 5px;
}

.uppy-Root [role~="tooltip"][data-microtip-position="right"]::after {
    /* no important */
    margin-left: 11px;
}

.uppy-Root [role~="tooltip"][data-microtip-position="right"]:hover::before,
.uppy-Root [role~="tooltip"][data-microtip-position="right"]:hover::after {
    /* no important */
    -webkit-transform: translate3d(0, -50%, 0);
            transform: translate3d(0, -50%, 0);
}


/* ------------------------------------------------
    [3] Size
  -------------------------------------------------*/

.uppy-Root [role~="tooltip"][data-microtip-size="small"]::after {
    /* no important */
    white-space: normal;
    white-space: initial;
    width: 80px;
}

.uppy-Root [role~="tooltip"][data-microtip-size="medium"]::after {
    /* no important */
    white-space: normal;
    white-space: initial;
    width: 150px;
}

.uppy-Root [role~="tooltip"][data-microtip-size="large"]::after {
    /* no important */
    white-space: normal;
    white-space: initial;
    width: 260px;
}

.uppy-StatusBar {
    display: -webkit-flex;
    display: flex;
    position: relative;
    height: 40px;
    line-height: 40px;
    font-size: 12px;
    font-weight: 400;
    color: #fff;
    background-color: #fff;
    z-index: 1001;
    transition: height .2s;
}

.uppy-size--md .uppy-StatusBar {
    height: 46px;
}

.uppy-StatusBar:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    background-color: #eaeaea;
}

.uppy-StatusBar[aria-hidden=true] {
    overflow-y: hidden;
    height: 0;
}

.uppy-StatusBar.is-complete .uppy-StatusBar-progress {
    background-color: #1bb240;
}

.uppy-StatusBar.is-error .uppy-StatusBar-progress {
    background-color: #e32437;
}

.uppy-StatusBar.is-complete .uppy-StatusBar-statusIndicator {
    color: #1bb240;
}

.uppy-StatusBar.is-error .uppy-StatusBar-statusIndicator {
    color: #e32437;
}

.uppy-StatusBar:not([aria-hidden=true]).is-waiting {
    background-color: #fff;
    height: 65px;
    border-top: 1px solid #eaeaea;
}

.uppy-StatusBar-progress {
    background-color: #2275d7;
    height: 2px;
    position: absolute;
    z-index: 1001;
    transition: background-color, width .3s ease-out;
}

.uppy-StatusBar-progress.is-indeterminate {
    background-size: 64px 64px;
    background-image: linear-gradient(45deg, rgba(0, 0, 0, 0.3) 25%, transparent 25%, transparent 50%, rgba(0, 0, 0, 0.3) 50%, rgba(0, 0, 0, 0.3) 75%, transparent 75%, transparent);
    -webkit-animation: uppy-StatusBar-ProgressStripes 1s linear infinite;
            animation: uppy-StatusBar-ProgressStripes 1s linear infinite;
}

@-webkit-keyframes uppy-StatusBar-ProgressStripes {
    from {
        background-position: 0 0;
    }
    to {
        background-position: 64px 0;
    }
}

@keyframes uppy-StatusBar-ProgressStripes {
    from {
        background-position: 0 0;
    }
    to {
        background-position: 64px 0;
    }
}

.uppy-StatusBar.is-preprocessing .uppy-StatusBar-progress,
.uppy-StatusBar.is-postprocessing .uppy-StatusBar-progress {
    background-color: #f6a623;
}

.uppy-StatusBar.is-waiting .uppy-StatusBar-progress {
    display: none;
}

.uppy-StatusBar-content {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    position: relative;
    z-index: 1002;
    padding-left: 10px;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: #333;
    height: 100%;
}

.uppy-size--md .uppy-StatusBar-content {
    padding-left: 15px;
}

.uppy-StatusBar-status {
    line-height: 1.4;
    font-weight: normal;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    padding-right: 0.3em;
}

.uppy-Root:not(.uppy-size--md) .uppy-StatusBar-additionalInfo {
    display: none;
}

.uppy-StatusBar-statusPrimary {
    font-weight: 500;
}

.uppy-StatusBar-statusSecondary {
    margin-top: 1px;
    font-size: 11px;
    line-height: 1.2;
    display: inline-block;
    color: #757575;
    white-space: nowrap;
}

.uppy-StatusBar-statusSecondaryHint {
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
    line-height: 1;
}

.uppy-size--md .uppy-StatusBar-statusSecondaryHint {
    margin-right: 8px;
}

.uppy-StatusBar-statusIndicator {
    position: relative;
    top: 1px;
    color: #525252;
    margin-right: 7px;
}

.uppy-StatusBar-statusIndicator svg {
    vertical-align: text-bottom;
}

.uppy-StatusBar-actions {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 10px;
    z-index: 1004;
}

.uppy-StatusBar.is-waiting .uppy-StatusBar-actions {
    width: 100%;
    position: static;
    padding: 0 15px;
    background-color: #fafafa;
}

.uppy-StatusBar-actionCircleBtn {
    line-height: 1;
    cursor: pointer;
    margin: 3px;
    opacity: 0.9;
}

.uppy-StatusBar-actionCircleBtn:focus {
    outline: none;
}

.uppy-StatusBar-actionCircleBtn::-moz-focus-inner {
    border: 0;
}

.uppy-StatusBar-actionCircleBtn:focus {
    box-shadow: 0 0 0 3px rgba(34, 117, 215, 0.5);
}

.uppy-StatusBar-actionCircleBtn:hover {
    opacity: 1;
}

.uppy-StatusBar-actionCircleBtn:focus {
    border-radius: 50%;
}

.uppy-StatusBar-actionCircleBtn svg {
    vertical-align: bottom;
}

.uppy-StatusBar-actionBtn {
    display: inline-block;
    vertical-align: middle;
    font-size: 10px;
    line-height: inherit;
    color: #2275d7;
}

.uppy-size--md .uppy-StatusBar-actionBtn {
    font-size: 11px;
}

.uppy-StatusBar-actionBtn--retry {
    height: 16px;
    border-radius: 8px;
    margin-right: 6px;
    background-color: #ff4b23;
    line-height: 1;
    color: #fff;
    padding: 1px 6px 3px 18px;
    position: relative;
}

.uppy-StatusBar-actionBtn--retry:focus {
    outline: none;
}

.uppy-StatusBar-actionBtn--retry::-moz-focus-inner {
    border: 0;
}

.uppy-StatusBar-actionBtn--retry:focus {
    box-shadow: 0 0 0 3px rgba(34, 117, 215, 0.5);
}

.uppy-StatusBar-actionBtn--retry:hover {
    background-color: #f92d00;
}

.uppy-StatusBar-actionBtn--retry svg {
    position: absolute;
    top: 3px;
    left: 6px;
}

.uppy-StatusBar.is-waiting .uppy-StatusBar-actionBtn--upload {
    font-size: 14px;
    width: 100%;
    padding: 15px 10px;
    color: #fff;
    background-color: #1bb240;
    line-height: 1;
}

.uppy-StatusBar.is-waiting .uppy-StatusBar-actionBtn--upload:hover {
    background-color: #148630;
}

.uppy-size--md .uppy-StatusBar.is-waiting .uppy-StatusBar-actionBtn--upload {
    padding: 13px 22px;
    width: auto;
}

.uppy-StatusBar:not(.is-waiting) .uppy-StatusBar-actionBtn--upload {
    background-color: transparent;
    color: #2275d7;
}

.uppy-StatusBar-actionBtn--uploadNewlyAdded {
    padding-right: 3px;
    padding-left: 3px;
    padding-bottom: 1px;
    border-radius: 3px;
}

.uppy-StatusBar-actionBtn--uploadNewlyAdded:focus {
    outline: none;
}

.uppy-StatusBar-actionBtn--uploadNewlyAdded::-moz-focus-inner {
    border: 0;
}

.uppy-StatusBar-actionBtn--uploadNewlyAdded:focus {
    box-shadow: 0 0 0 3px rgba(34, 117, 215, 0.5);
}

.uppy-StatusBar-details {
    line-height: 12px;
    width: 13px;
    height: 13px;
    display: inline-block;
    vertical-align: middle;
    color: #fff;
    background-color: #939393;
    border-radius: 50%;
    position: relative;
    top: 0;
    left: 2px;
    font-size: 10px;
    font-weight: 600;
    text-align: center;
    cursor: help;
}

.uppy-StatusBar-details:after {
    line-height: 1.3;
    word-wrap: break-word;
}

.uppy-StatusBar-spinner {
    -webkit-animation-name: uppy-StatusBar-spinnerAnimation;
            animation-name: uppy-StatusBar-spinnerAnimation;
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
    margin-right: 10px;
    fill: #2275d7;
}

.uppy-StatusBar.is-preprocessing .uppy-StatusBar-spinner,
.uppy-StatusBar.is-postprocessing .uppy-StatusBar-spinner {
    fill: #f6a623;
}

@-webkit-keyframes uppy-StatusBar-spinnerAnimation {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

@keyframes uppy-StatusBar-spinnerAnimation {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

.uppy-ProviderBrowser-viewType--grid ul.uppy-ProviderBrowser-list {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    padding: 6px;
}

.uppy-ProviderBrowser-viewType--grid ul.uppy-ProviderBrowser-list::after {
    content: '';
    -webkit-flex: auto;
            flex: auto;
}

.uppy-ProviderBrowser-viewType--grid li.uppy-ProviderBrowserItem {
    width: 50%;
    position: relative;
    margin: 0;
}

.uppy-size--md .uppy-ProviderBrowser-viewType--grid li.uppy-ProviderBrowserItem {
    width: 33.3333%;
}

.uppy-size--lg .uppy-ProviderBrowser-viewType--grid li.uppy-ProviderBrowserItem {
    width: 25%;
}

.uppy-ProviderBrowser-viewType--grid li.uppy-ProviderBrowserItem::before {
    content: '';
    padding-top: 100%;
    display: block;
}

.uppy-ProviderBrowser-viewType--grid li.uppy-ProviderBrowserItem--selected img,
.uppy-ProviderBrowser-viewType--grid li.uppy-ProviderBrowserItem--selected svg {
    opacity: 0.85;
}

.uppy-ProviderBrowser-viewType--grid li.uppy-ProviderBrowserItem--noPreview .uppy-ProviderBrowserItem-inner {
    background-color: rgba(147, 147, 147, 0.3);
}

.uppy-ProviderBrowser-viewType--grid li.uppy-ProviderBrowserItem--noPreview svg {
    fill: rgba(0, 0, 0, 0.7);
    width: 30%;
    height: 30%;
}

.uppy-ProviderBrowser-viewType--grid button.uppy-ProviderBrowserItem-inner {
    border-radius: 4px;
    overflow: hidden;
    position: absolute;
    top: 7px;
    left: 7px;
    right: 7px;
    bottom: 7px;
    text-align: center;
    width: calc(100% - 14px);
    height: calc(100% - 14px);
}

.uppy-ProviderBrowser-viewType--grid button.uppy-ProviderBrowserItem-inner:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(34, 117, 215, 0.9);
}

.uppy-ProviderBrowser-viewType--grid button.uppy-ProviderBrowserItem-inner img,
.uppy-ProviderBrowser-viewType--grid button.uppy-ProviderBrowserItem-inner svg {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 4px;
}

.uppy-ProviderBrowser-viewType--grid .uppy-ProviderBrowserItem-fakeCheckbox {
    position: absolute;
    top: 16px;
    right: 16px;
    width: 26px;
    height: 26px;
    background-color: #2275d7;
    border-radius: 50%;
    z-index: 1002;
    opacity: 0;
}

.uppy-ProviderBrowser-viewType--grid .uppy-ProviderBrowserItem-fakeCheckbox:after {
    width: 12px;
    height: 7px;
    left: 7px;
    top: 8px;
}

.uppy-ProviderBrowser-viewType--grid .uppy-ProviderBrowserItem-fakeCheckbox--is-checked {
    opacity: 1;
}

.uppy-ProviderBrowser-viewType--list {
    background-color: #fff;
}

.uppy-ProviderBrowser-viewType--list li.uppy-ProviderBrowserItem {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    padding: 7px 15px;
    margin: 0;
}

.uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-fakeCheckbox {
    margin-right: 15px;
    height: 17px;
    width: 17px;
    border-radius: 3px;
    background-color: #fff;
    border: 1px solid #cfcfcf;
}

.uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-fakeCheckbox:focus {
    border: 1px solid #2275d7;
    box-shadow: 0 0 0 3px rgba(34, 117, 215, 0.25);
    outline: none;
}

.uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-fakeCheckbox::after {
    opacity: 0;
    height: 5px;
    width: 9px;
    left: 3px;
    top: 4px;
}

.uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-fakeCheckbox--is-checked {
    background-color: #2275d7;
    border-color: #2275d7;
}

.uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-fakeCheckbox--is-checked::after {
    opacity: 1;
}

.uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-inner {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    padding: 2px;
}

.uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-inner:focus {
    outline: none;
    text-decoration: underline;
}

.uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-inner img,
.uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-inner svg {
    margin-right: 8px;
    max-width: 20px;
    max-height: 20px;
}

.uppy-ProviderBrowserItem-fakeCheckbox {
    position: relative;
    cursor: pointer;
    -webkit-flex-shrink: 0;
            flex-shrink: 0;
}

.uppy-ProviderBrowserItem-fakeCheckbox::after {
    content: '';
    position: absolute;
    cursor: pointer;
    border-left: 2px solid #fff;
    border-bottom: 2px solid #fff;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
}

.uppy-DashboardContent-panelBody {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex: 1 1;
            flex: 1 1;
}

.uppy-Provider-auth,
.uppy-Provider-error,
.uppy-Provider-loading,
.uppy-Provider-empty {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-flow: column wrap;
            flex-flow: column wrap;
    -webkit-flex: 1 1;
            flex: 1 1;
    color: #939393;
}

.uppy-Provider-empty {
    color: #939393;
}

.uppy-Provider-authIcon svg {
    width: 100px;
    height: 75px;
    margin-bottom: 15px;
}

.uppy-Provider-authTitle {
    font-size: 17px;
    line-height: 1.4;
    font-weight: 400;
    margin-bottom: 30px;
    padding: 0 15px;
    max-width: 500px;
    text-align: center;
    color: #757575;
}

.uppy-size--md .uppy-Provider-authTitle {
    font-size: 20px;
}

.uppy-Provider-breadcrumbs {
    -webkit-flex: 1 1;
            flex: 1 1;
    color: #525252;
    font-size: 12px;
    margin-bottom: 10px;
    text-align: left;
}

.uppy-size--md .uppy-Provider-breadcrumbs {
    margin-bottom: 0;
}

.uppy-Provider-breadcrumbsIcon {
    display: inline-block;
    color: #525252;
    vertical-align: middle;
    margin-right: 4px;
    line-height: 1;
}

.uppy-Provider-breadcrumbsIcon svg {
    width: 13px;
    height: 13px;
    fill: #525252;
}

.uppy-Provider-breadcrumbs button {
    display: inline-block;
    line-height: inherit;
    padding: 4px;
    border-radius: 3px;
}

.uppy-Provider-breadcrumbs button:focus {
    outline: none;
}

.uppy-Provider-breadcrumbs button::-moz-focus-inner {
    border: 0;
}

.uppy-Provider-breadcrumbs button:hover {
    color: #1b5dab;
}

.uppy-Provider-breadcrumbs button:focus {
    background-color: #eceef2;
}

.uppy-Provider-breadcrumbs button:hover {
    text-decoration: underline;
    cursor: pointer;
}

.uppy-ProviderBrowser {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-flex: 1 1;
            flex: 1 1;
    font-size: 14px;
    font-weight: 400;
    height: 100%;
}

.uppy-ProviderBrowser-user {
    margin: 0 8px 0 0;
    font-weight: 500;
    color: #333;
}

.uppy-ProviderBrowser-user:after {
    content: '\00B7';
    position: relative;
    left: 4px;
    color: #939393;
    font-weight: normal;
}

.uppy-ProviderBrowser-header {
    z-index: 1001;
    border-bottom: 1px solid #eaeaea;
    position: relative;
}

.uppy-ProviderBrowser-headerBar {
    padding: 7px 15px;
    background-color: #fafafa;
    z-index: 1001;
    color: #757575;
    line-height: 1.4;
    font-size: 12px;
}

.uppy-size--md .uppy-ProviderBrowser-headerBar {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

.uppy-ProviderBrowser-headerBar--simple {
    text-align: center;
    display: block;
    -webkit-justify-content: center;
            justify-content: center;
}

.uppy-ProviderBrowser-headerBar--simple .uppy-Provider-breadcrumbsWrap {
    -webkit-flex: none;
            flex: none;
    display: inline-block;
    vertical-align: middle;
}

.uppy-ProviderBrowser-search {
    width: 100%;
    background-color: #fff;
    position: relative;
    height: 30px;
    margin-top: 10px;
    margin-bottom: 5px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

.uppy-ProviderBrowser-searchIcon {
    position: absolute;
    width: 12px;
    height: 12px;
    left: 16px;
    z-index: 1002;
    color: #bbb;
}

.uppy-ProviderBrowser-searchInput {
    width: 100%;
    height: 30px;
    background-color: transparent;
    outline: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 12px;
    line-height: 1.4;
    border: 0;
    margin: 0 8px;
    padding-left: 27px;
    z-index: 1001;
    border-radius: 4px;
}

.uppy-ProviderBrowser-searchInput:focus {
    outline: 0;
    background-color: #f4f4f4;
}

.uppy-ProviderBrowser-searchClose {
    position: absolute;
    width: 22px;
    height: 22px;
    padding: 6px;
    right: 12px;
    top: 4px;
    z-index: 1002;
    color: #939393;
    cursor: pointer;
}

.uppy-ProviderBrowser-searchClose:hover {
    color: #757575;
}

.uppy-ProviderBrowser-searchClose svg {
    vertical-align: text-top;
}

.uppy-ProviderBrowser-searchInput:-ms-input-placeholder {
    color: #939393;
    opacity: 1;
}

.uppy-ProviderBrowser-searchInput::-webkit-input-placeholder {
    color: #939393;
    opacity: 1;
}

.uppy-ProviderBrowser-searchInput::placeholder {
    color: #939393;
    opacity: 1;
}

.uppy-ProviderBrowser-userLogout {
    cursor: pointer;
    line-height: inherit;
    color: #2275d7;
    padding: 4px;
    border-radius: 3px;
}

.uppy-ProviderBrowser-userLogout:focus {
    outline: none;
}

.uppy-ProviderBrowser-userLogout::-moz-focus-inner {
    border: 0;
}

.uppy-ProviderBrowser-userLogout:hover {
    color: #1b5dab;
}

.uppy-ProviderBrowser-userLogout:focus {
    background-color: #eceef2;
}

.uppy-ProviderBrowser-userLogout:hover {
    text-decoration: underline;
}

.uppy-ProviderBrowser-body {
    -webkit-flex: 1 1;
            flex: 1 1;
    position: relative;
}

.uppy-ProviderBrowser-list {
    -webkit-flex: 1 1;
            flex: 1 1;
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    background-color: #fff;
    border-spacing: 0;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    list-style: none;
    margin: 0;
    padding: 0;
}

.uppy-ProviderBrowserItem-inner {
    cursor: pointer;
    font-weight: 500;
    font-size: 13px;
}

.uppy-ProviderBrowser-footer {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    background: #fff;
    height: 65px;
    border-top: 1px solid #eaeaea;
    padding: 0 15px;
}

.uppy-ProviderBrowser-footer button {
    margin-right: 8px;
}

.uppy-DashboardItem-previewInnerWrap {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-direction: column;
            flex-direction: column;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.4);
    border-radius: 3px;
}

.uppy-size--md .uppy-DashboardItem-previewInnerWrap {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
}

.uppy-DashboardItem-previewInnerWrap:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.65);
    display: none;
    z-index: 1001;
}

.uppy-DashboardItem-previewLink {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1002;
}

.uppy-DashboardItem-previewLink:focus {
    box-shadow: inset 0 0 0 3px #76abe9;
}

.uppy-DashboardItem-preview img.uppy-DashboardItem-previewImg {
    width: 100%;
    height: 100%;
    object-fit: cover;
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    border-radius: 3px;
}

.uppy-DashboardItem-progress {
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    z-index: 1002;
    color: #fff;
    text-align: center;
    width: 120px;
    transition: all .35 ease;
}

.uppy-DashboardItem-progressIndicator {
    display: inline-block;
    width: 38px;
    height: 38px;
    opacity: 0.9;
    cursor: pointer;
}

.uppy-DashboardItem-progressIndicator:focus {
    outline: none;
}

.uppy-DashboardItem-progressIndicator::-moz-focus-inner {
    border: 0;
}

.uppy-DashboardItem-progressIndicator:focus svg.UppyIcon-progressCircle .bg,
.uppy-DashboardItem-progressIndicator:focus svg.retry {
    fill: #76abe9;
}

svg.UppyIcon-progressCircle {
    width: 100%;
    height: 100%;
}

svg.UppyIcon-progressCircle .bg {
    stroke: rgba(255, 255, 255, 0.4);
    opacity: 0;
}

svg.UppyIcon-progressCircle .progress {
    stroke: #fff;
    transition: stroke-dashoffset .5s ease-out;
    opacity: 0;
}

svg.UppyIcon-progressCircle .play {
    stroke: #fff;
    fill: #fff;
    opacity: 0;
    transition: all 0.2s;
    display: none;
}

svg.UppyIcon-progressCircle .cancel {
    fill: #fff;
    opacity: 0;
    transition: all 0.2s;
}

svg.UppyIcon-progressCircle .pause {
    stroke: #fff;
    fill: #fff;
    opacity: 0;
    transition: all 0.2s;
    display: none;
}

svg.UppyIcon-progressCircle .check {
    opacity: 0;
    fill: #fff;
    transition: all 0.2s;
}

svg.UppyIcon.retry {
    fill: #fff;
}

.uppy-DashboardItem.is-complete .uppy-DashboardItem-progress {
    -webkit-transform: none;
            transform: none;
    -webkit-transform: initial;
            transform: initial;
    top: -9px;
    right: -8px;
    left: auto;
    left: initial;
    width: auto;
}

.uppy-DashboardItem.is-inprogress .uppy-DashboardItem-progress,
.uppy-DashboardItem.is-complete .uppy-DashboardItem-progress,
.uppy-DashboardItem.is-error .uppy-DashboardItem-progress {
    display: block;
}

.uppy-DashboardItem.is-error .uppy-DashboardItem-progressIndicator {
    width: 18px;
    height: 18px;
}

.uppy-size--md .uppy-DashboardItem.is-error .uppy-DashboardItem-progressIndicator {
    width: 28px;
    height: 28px;
}

.uppy-DashboardItem.is-complete .uppy-DashboardItem-progressIndicator {
    width: 18px;
    height: 18px;
    opacity: 1;
}

.uppy-size--md .uppy-DashboardItem.is-complete .uppy-DashboardItem-progressIndicator {
    width: 22px;
    height: 22px;
}

.uppy-DashboardItem.is-paused svg.UppyIcon-progressCircle .pause {
    opacity: 0;
}

.uppy-DashboardItem.is-paused svg.UppyIcon-progressCircle .play {
    opacity: 1;
}

.uppy-DashboardItem.is-noIndividualCancellation .uppy-DashboardItem-progressIndicator {
    cursor: default;
}

.uppy-DashboardItem.is-noIndividualCancellation .cancel {
    display: none;
}

.uppy-DashboardItem.is-processing .uppy-DashboardItem-progress {
    opacity: 0;
}

.uppy-DashboardItem.is-complete .uppy-DashboardItem-progressIndicator {
    cursor: default;
}

.uppy-DashboardItem.is-complete .progress {
    stroke: #1bb240;
    fill: #1bb240;
    opacity: 1;
}

.uppy-DashboardItem.is-complete .check {
    opacity: 1;
}

.uppy-size--md .uppy-DashboardItem-progressIndicator {
    width: 55px;
    height: 55px;
}

.uppy-DashboardItem.is-resumable .pause,
.uppy-DashboardItem.is-resumable .play {
    display: block;
}

.uppy-DashboardItem.is-resumable .cancel {
    display: none;
}

.uppy-DashboardItem.is-inprogress .bg,
.uppy-DashboardItem.is-inprogress .progress,
.uppy-DashboardItem.is-inprogress .pause,
.uppy-DashboardItem.is-inprogress .cancel {
    opacity: 1;
}

.uppy-DashboardItem-fileInfo {
    padding-right: 5px;
}

.uppy-DashboardItem-name {
    font-size: 12px;
    line-height: 1.3;
    font-weight: 500;
    margin-bottom: 4px;
    word-break: break-all;
    word-wrap: anywhere;
}

.uppy-DashboardItem-status {
    font-size: 11px;
    line-height: 1.3;
    font-weight: normal;
    color: #757575;
}

.uppy-DashboardItem-statusSize {
    display: inline-block;
    vertical-align: bottom;
    text-transform: uppercase;
}

div[specification="profile-picture"] .uppy-DashboardItem-statusSize { 
    display: none;
}

.uppy-DashboardItem-sourceIcon {
    display: none;
    vertical-align: bottom;
    color: #bbb;
}

.uppy-DashboardItem-sourceIcon:not(:first-child) {
    position: relative;
    margin-left: 14px;
}

.uppy-DashboardItem-sourceIcon svg,
.uppy-DashboardItem-sourceIcon svg * {
    max-width: 100%;
    max-height: 100%;
    display: inline-block;
    vertical-align: text-bottom;
    overflow: hidden;
    fill: currentColor;
    width: 11px;
    height: 12px;
}

.uppy-DashboardItem-action {
    cursor: pointer;
    color: #939393;
}

.uppy-DashboardItem-action:focus {
    outline: none;
}

.uppy-DashboardItem-action::-moz-focus-inner {
    border: 0;
}

.uppy-DashboardItem-action:focus {
    box-shadow: 0 0 0 3px rgba(34, 117, 215, 0.5);
}

.uppy-DashboardItem-action:hover {
    opacity: 1;
    color: #1f1f1f;
}

.uppy-DashboardItem-action--remove {
    color: #1f1f1f;
    opacity: 0.95;
}

.uppy-Dashboard:not(.uppy-size--md) .uppy-DashboardItem-actionWrapper {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

.uppy-Dashboard:not(.uppy-size--md) .uppy-DashboardItem-action {
    width: 22px;
    height: 22px;
    padding: 3px;
    margin-left: 3px;
}

.uppy-Dashboard:not(.uppy-size--md) .uppy-DashboardItem-action:focus {
    border-radius: 3px;
}

.uppy-size--md .uppy-DashboardItem-action--copyLink,
.uppy-size--md .uppy-DashboardItem-action--edit {
    width: 16px;
    height: 16px;
    padding: 0;
}

.uppy-size--md .uppy-DashboardItem-action--copyLink:focus,
.uppy-size--md .uppy-DashboardItem-action--edit:focus {
    border-radius: 3px;
}

.uppy-size--md .uppy-DashboardItem-action--remove {
    z-index: 1002;
    position: absolute;
    top: -8px;
    right: -8px;
    width: 18px;
    height: 18px;
    padding: 0;
}

.uppy-size--md .uppy-DashboardItem-action--remove:focus {
    border-radius: 50%;
}

.uppy-Dashboard:not(.uppy-size--md) .uppy-DashboardItem {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    border-bottom: 1px solid #eaeaea;
    padding: 10px;
    padding-right: 0;
}

.uppy-size--md .uppy-DashboardItem {
    position: relative;
    display: block;
    float: left;
    margin: 5px 15px;
    width: calc(33.333% - 15px - 15px);
    height: 215px;
}

.uppy-size--lg .uppy-DashboardItem {
    margin: 5px 15px;
    width: calc(25% - 15px - 15px);
    height: 190px;
}

.uppy-size--xl .uppy-DashboardItem {
    width: calc(20% - 15px - 15px);
    height: 210px;
}

.uppy-DashboardItem-preview {
    position: relative;
}

.uppy-Dashboard:not(.uppy-size--md) .uppy-DashboardItem-preview {
    -webkit-flex-shrink: 0;
            flex-shrink: 0;
    -webkit-flex-grow: 0;
            flex-grow: 0;
    width: 50px;
    height: 50px;
}

.uppy-size--md .uppy-DashboardItem-preview {
    width: 100%;
    height: 140px;
}

.uppy-size--lg .uppy-DashboardItem-preview {
    height: 120px;
}

.uppy-size--xl .uppy-DashboardItem-preview {
    height: 140px;
}

.uppy-DashboardItem-fileInfoAndButtons {
    -webkit-flex-grow: 1;
            flex-grow: 1;
    padding-right: 8px;
    padding-left: 12px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.uppy-size--md .uppy-DashboardItem-fileInfoAndButtons {
    -webkit-align-items: flex-start;
            align-items: flex-start;
    width: 100%;
    padding: 0;
    padding-top: 9px;
}

.uppy-DashboardItem-fileInfo {
    -webkit-flex-grow: 1;
            flex-grow: 1;
    -webkit-flex-shrink: 1;
            flex-shrink: 1;
}

.uppy-DashboardItem-actionWrapper {
    -webkit-flex-grow: 0;
            flex-grow: 0;
    -webkit-flex-shrink: 0;
            flex-shrink: 0;
}

.uppy-DashboardItem.is-inprogress .uppy-DashboardItem-previewInnerWrap:after {
    display: block;
}

.uppy-DashboardItem.is-error .uppy-DashboardItem-previewInnerWrap:after {
    display: block;
}

.uppy-DashboardItem.is-inprogress:not(.is-resumable) .uppy-DashboardItem-action--remove {
    display: none;
}

.uppy-Dashboard-FileCard {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1005;
    box-shadow: 0px 0px 10px 4px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    border-radius: 5px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.uppy-Dashboard-FileCard .uppy-DashboardContent-bar {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.uppy-Dashboard-FileCard .uppy-Dashboard-FileCard-actions {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.uppy-Dashboard-FileCard-inner {
    height: 100%;
    -webkit-flex-grow: 1;
            flex-grow: 1;
    -webkit-flex-shrink: 1;
            flex-shrink: 1;
    min-height: 0;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.uppy-Dashboard-FileCard-preview {
    height: 60%;
    -webkit-flex-grow: 0;
            flex-grow: 0;
    -webkit-flex-shrink: 1;
            flex-shrink: 1;
    min-height: 0;
    border-bottom: 1px solid #eaeaea;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.uppy-Dashboard-FileCard-preview img.uppy-DashboardItem-previewImg {
    max-width: 90%;
    max-height: 90%;
    object-fit: cover;
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    border-radius: 3px;
    box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.15);
}

.uppy-Dashboard-FileCard-info {
    height: 40%;
    -webkit-flex-grow: 0;
            flex-grow: 0;
    -webkit-flex-shrink: 0;
            flex-shrink: 0;
    padding: 30px 20px 20px 20px;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}

.uppy-Dashboard-FileCard-fieldset {
    font-size: 0;
    border: 0;
    padding: 0;
    max-width: 640px;
    margin: auto;
    margin-bottom: 12px;
}

.uppy-Dashboard-FileCard-label {
    display: inline-block;
    vertical-align: middle;
    width: 22%;
    font-size: 12px;
    color: #525252;
}

.uppy-size--md .uppy-Dashboard-FileCard-label {
    font-size: 14px;
}

.uppy-Dashboard-FileCard-input {
    display: inline-block;
    vertical-align: middle;
    width: 78%;
}

.uppy-Dashboard-FileCard-actions {
    height: 55px;
    -webkit-flex-shrink: 0;
            flex-shrink: 0;
    -webkit-flex-grow: 0;
            flex-grow: 0;
    border-top: 1px solid #eaeaea;
    padding: 0 15px;
    background-color: #fafafa;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

.uppy-size--md .uppy-Dashboard-FileCard-actions {
    height: 65px;
}

.uppy-Dashboard-FileCard-actionsBtn {
    margin-right: 10px;
}

.uppy-transition-slideDownUp-enter {
    opacity: 0.01;
    -webkit-transform: translate3d(0, -105%, 0);
            transform: translate3d(0, -105%, 0);
    transition: opacity 0.25s ease-in-out, -webkit-transform 0.25s ease-in-out;
    transition: transform 0.25s ease-in-out, opacity 0.25s ease-in-out;
    transition: transform 0.25s ease-in-out, opacity 0.25s ease-in-out, -webkit-transform 0.25s ease-in-out;
}

.uppy-transition-slideDownUp-enter.uppy-transition-slideDownUp-enter-active {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
}

.uppy-transition-slideDownUp-leave {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    transition: opacity 0.25s ease-in-out, -webkit-transform 0.25s ease-in-out;
    transition: transform 0.25s ease-in-out, opacity 0.25s ease-in-out;
    transition: transform 0.25s ease-in-out, opacity 0.25s ease-in-out, -webkit-transform 0.25s ease-in-out;
}

.uppy-transition-slideDownUp-leave.uppy-transition-slideDownUp-leave-active {
    opacity: 0.01;
    -webkit-transform: translate3d(0, -105%, 0);
            transform: translate3d(0, -105%, 0);
}

.uppy-Dashboard--modal {
    z-index: 10001;
}

.uppy-Dashboard--modal[aria-hidden=true] {
    display: none;
}

@-webkit-keyframes uppy-Dashboard-fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes uppy-Dashboard-fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@-webkit-keyframes uppy-Dashboard-fadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

@keyframes uppy-Dashboard-fadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

@-webkit-keyframes uppy-Dashboard-slideDownAndFadeIn {
    from {
        -webkit-transform: translate3d(-50%, -70%, 0);
                transform: translate3d(-50%, -70%, 0);
        opacity: 0;
    }
    to {
        -webkit-transform: translate3d(-50%, -50%, 0);
                transform: translate3d(-50%, -50%, 0);
        opacity: 1;
    }
}

@keyframes uppy-Dashboard-slideDownAndFadeIn {
    from {
        -webkit-transform: translate3d(-50%, -70%, 0);
                transform: translate3d(-50%, -70%, 0);
        opacity: 0;
    }
    to {
        -webkit-transform: translate3d(-50%, -50%, 0);
                transform: translate3d(-50%, -50%, 0);
        opacity: 1;
    }
}

@-webkit-keyframes uppy-Dashboard-slideDownAndFadeIn--small {
    from {
        -webkit-transform: translate3d(0, -20%, 0);
                transform: translate3d(0, -20%, 0);
        opacity: 0;
    }
    to {
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}

@keyframes uppy-Dashboard-slideDownAndFadeIn--small {
    from {
        -webkit-transform: translate3d(0, -20%, 0);
                transform: translate3d(0, -20%, 0);
        opacity: 0;
    }
    to {
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}

@-webkit-keyframes uppy-Dashboard-slideUpFadeOut {
    from {
        -webkit-transform: translate3d(-50%, -50%, 0);
                transform: translate3d(-50%, -50%, 0);
        opacity: 1;
    }
    to {
        -webkit-transform: translate3d(-50%, -70%, 0);
                transform: translate3d(-50%, -70%, 0);
        opacity: 0;
    }
}

@keyframes uppy-Dashboard-slideUpFadeOut {
    from {
        -webkit-transform: translate3d(-50%, -50%, 0);
                transform: translate3d(-50%, -50%, 0);
        opacity: 1;
    }
    to {
        -webkit-transform: translate3d(-50%, -70%, 0);
                transform: translate3d(-50%, -70%, 0);
        opacity: 0;
    }
}

@-webkit-keyframes uppy-Dashboard-slideUpFadeOut--small {
    from {
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
        opacity: 1;
    }
    to {
        -webkit-transform: translate3d(0, -20%, 0);
                transform: translate3d(0, -20%, 0);
        opacity: 0;
    }
}

@keyframes uppy-Dashboard-slideUpFadeOut--small {
    from {
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
        opacity: 1;
    }
    to {
        -webkit-transform: translate3d(0, -20%, 0);
                transform: translate3d(0, -20%, 0);
        opacity: 0;
    }
}

.uppy-Dashboard--modal.uppy-Dashboard--animateOpenClose>.uppy-Dashboard-inner {
    -webkit-animation: uppy-Dashboard-slideDownAndFadeIn--small 0.3s cubic-bezier(0, 0, 0.2, 1);
            animation: uppy-Dashboard-slideDownAndFadeIn--small 0.3s cubic-bezier(0, 0, 0.2, 1);
}

@media only screen and (min-width: 820px) {
    .uppy-Dashboard--modal.uppy-Dashboard--animateOpenClose>.uppy-Dashboard-inner {
        -webkit-animation: uppy-Dashboard-slideDownAndFadeIn 0.3s cubic-bezier(0, 0, 0.2, 1);
                animation: uppy-Dashboard-slideDownAndFadeIn 0.3s cubic-bezier(0, 0, 0.2, 1);
    }
}

.uppy-Dashboard--modal.uppy-Dashboard--animateOpenClose>.uppy-Dashboard-overlay {
    -webkit-animation: uppy-Dashboard-fadeIn 0.3s cubic-bezier(0, 0, 0.2, 1);
            animation: uppy-Dashboard-fadeIn 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.uppy-Dashboard--modal.uppy-Dashboard--animateOpenClose.uppy-Dashboard--isClosing>.uppy-Dashboard-inner {
    -webkit-animation: uppy-Dashboard-slideUpFadeOut--small 0.3s cubic-bezier(0, 0, 0.2, 1);
            animation: uppy-Dashboard-slideUpFadeOut--small 0.3s cubic-bezier(0, 0, 0.2, 1);
}

@media only screen and (min-width: 820px) {
    .uppy-Dashboard--modal.uppy-Dashboard--animateOpenClose.uppy-Dashboard--isClosing>.uppy-Dashboard-inner {
        -webkit-animation: uppy-Dashboard-slideUpFadeOut 0.3s cubic-bezier(0, 0, 0.2, 1);
                animation: uppy-Dashboard-slideUpFadeOut 0.3s cubic-bezier(0, 0, 0.2, 1);
    }
}

.uppy-Dashboard--modal.uppy-Dashboard--animateOpenClose.uppy-Dashboard--isClosing>.uppy-Dashboard-overlay {
    -webkit-animation: uppy-Dashboard-fadeOut 0.3s cubic-bezier(0, 0, 0.2, 1);
            animation: uppy-Dashboard-fadeOut 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.uppy-Dashboard-isFixed {
    overflow: hidden;
    height: 100vh;
}

.uppy-Dashboard--modal .uppy-Dashboard-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1001;
}

.uppy-Dashboard-inner {
    position: relative;
    margin-top: 55px;
    background-color: #fafafa;
    max-width: 100%;
    /* no !important */
    max-height: 72vh;
    /* no !important */
    /* min-height: 450px; */
    outline: none;
    border: 1px solid #eaeaea;
    border-radius: 5px;
}

.uppy-size--md .uppy-Dashboard-inner {
    min-height: auto;
}

@media only screen and (min-width: 820px) {
    .uppy-Dashboard-inner {
        width: 750px;
        /* no !important */
        height: 550px;
        /* no !important */
    }
}

.uppy-Dashboard--modal .uppy-Dashboard-inner {
    z-index: 1002;
}

.uppy-Dashboard-innerWrap {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    height: 100%;
    overflow: hidden;
    position: relative;
    border-radius: 5px;
    opacity: 0;
}

.uppy-Dashboard--isInnerWrapVisible .uppy-Dashboard-innerWrap {
    opacity: 1;
}

.uppy-Dashboard--modal .uppy-Dashboard-inner {
    position: fixed;
    top: 35px;
    left: 15px;
    right: 15px;
    bottom: 15px;
    margin-top: 32px;
    border: none;
}

@media only screen and (min-width: 820px) {
    .uppy-Dashboard--modal .uppy-Dashboard-inner {
        top: 40vh;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        box-shadow: 0 5px 15px 4px rgba(0, 0, 0, 0.15);
    }
}

.uppy-Dashboard-close {
    display: block;
    position: absolute;
    top: -33px;
    right: -2px;
    cursor: pointer;
    color: rgba(255, 255, 255, 0.9);
    font-size: 27px;
    z-index: 1005;
}

.uppy-Dashboard-close:focus {
    outline: none;
}

.uppy-Dashboard-close::-moz-focus-inner {
    border: 0;
}

.uppy-Dashboard-close:focus {
    color: #8cb8ed;
}

@media only screen and (min-width: 820px) {
    .uppy-Dashboard-close {
        font-size: 35px;
        top: -10px;
        right: -35px;
    }
}

.uppy-DashboardAddFiles {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-direction: column;
            flex-direction: column;
    height: 100%;
    position: relative;
    text-align: center;
    -webkit-flex: 1 1;
            flex: 1 1;
}

.uppy-size--md .uppy-DashboardAddFiles {
    margin: 7px;
    border-radius: 3px;
    border: 1px dashed #dfdfdf;
}

.uppy-Dashboard-AddFilesPanel .uppy-DashboardAddFiles {
    border: none;
}

.uppy-Dashboard--modal .uppy-DashboardAddFiles {
    border-color: #cfcfcf;
}

.uppy-DashboardTabs {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    width: 100%;
}

.uppy-size--md .uppy-DashboardTabs {
    -webkit-align-items: center;
            align-items: center;
}

.uppy-DashboardTabs-title {
    font-size: 14px;
    line-height: 30px;
    font-weight: 400;
    margin: 0;
    padding: 0;
    text-align: center;
    color: #525252;
}

.uppy-size--md .uppy-DashboardTabs-title {
    font-size: 16px;
    line-height: 40px;
}

.uppy-DashboardAddFiles-info {
    padding-top: 15px;
    padding-bottom: 15px;
}

.uppy-size--md .uppy-DashboardAddFiles-info {
    position: absolute;
    bottom: 30px;
    left: 0;
    right: 0;
    padding-top: 30px;
    padding-bottom: 0;
}

.uppy-Dashboard-browse {
    cursor: pointer;
    color: rgba(34, 117, 215, 0.9);
}

.uppy-Dashboard-browse:focus {
    outline: none;
}

.uppy-Dashboard-browse::-moz-focus-inner {
    border: 0;
}

.uppy-Dashboard-browse:hover,
.uppy-Dashboard-browse:focus {
    border-bottom: 2px solid #2275d7;
}

.uppy-DashboardTabs-list {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    max-height: 300px;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    margin-top: 10px;
    padding: 2px 0;
}

.uppy-size--md .uppy-DashboardTabs-list {
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-justify-content: center;
            justify-content: center;
    max-width: 600px;
    overflow-x: visible;
    overflow-x: initial;
    margin-top: 15px;
    padding-top: 0;
}

.uppy-DashboardTab {
    width: 100%;
    display: inline-block;
    text-align: center;
    border-bottom: 1px solid #eaeaea;
    padding: 0px 2px;
}

.uppy-size--md .uppy-DashboardTab {
    width: auto;
    width: initial;
    margin-bottom: 20px;
    border-bottom: none;
    padding: 0;
}

.uppy-DashboardTab-btn {
    width: 100%;
    height: 100%;
    cursor: pointer;
    border: 0;
    background-color: transparent;
    -webkit-appearance: none;
    appearance: none;
    color: #525252;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
    padding: 12px 15px;
    line-height: 1;
    text-align: center;
}

.uppy-size--md .uppy-DashboardTab-btn {
    width: 86px;
    margin-right: 1px;
    -webkit-flex-direction: column;
            flex-direction: column;
    padding: 10px 3px;
    border-radius: 5px;
}

.uppy-DashboardTab-btn::-moz-focus-inner {
    border: 0;
}

.uppy-DashboardTab-btn:hover {
    background-color: #f1f3f6;
}

.uppy-DashboardTab-btn:active,
.uppy-DashboardTab-btn:focus {
    background-color: #eceef2;
    outline: none;
}

.uppy-DashboardTab-btn svg {
    margin-right: 10px;
}

.uppy-size--md .uppy-DashboardTab-btn svg {
    margin-right: 0;
}

.uppy-DashboardTab-btn svg {
    max-width: 100%;
    max-height: 100%;
    display: inline-block;
    vertical-align: text-top;
    overflow: hidden;
    transition: -webkit-transform ease-in-out .15s;
    transition: transform ease-in-out .15s;
    transition: transform ease-in-out .15s, -webkit-transform ease-in-out .15s;
}

.uppy-DashboardTab-name {
    font-size: 14px;
    font-weight: 500;
}

.uppy-size--md .uppy-DashboardTab-name {
    font-size: 11px;
    line-height: 14px;
    margin-top: 8px;
    margin-bottom: 0;
}

.uppy-DashboardTab svg {
    width: 18px;
    height: 18px;
    vertical-align: middle;
}

.uppy-size--md .uppy-DashboardTab svg {
    width: 27px;
    height: 27px;
}

.uppy-Dashboard-input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

.uppy-DashboardContent-bar {
    -webkit-flex-shrink: 0;
            flex-shrink: 0;
    height: 40px;
    width: 100%;
    padding: 0 10px;
    z-index: 1004;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    position: relative;
    border-bottom: 1px solid #eaeaea;
    background-color: #fafafa;
}

.uppy-size--md .uppy-DashboardContent-bar {
    height: 50px;
    padding: 0 15px;
}

.uppy-DashboardContent-title {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 12px;
    line-height: 40px;
    font-weight: 500;
    width: 100%;
    max-width: 170px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow-x: hidden;
    margin: auto;
}

.uppy-size--md .uppy-DashboardContent-title {
    font-size: 14px;
    line-height: 50px;
    max-width: 300px;
}

.uppy-DashboardContent-back {
    background: none;
    -webkit-appearance: none;
    font-family: inherit;
    font-size: inherit;
    line-height: 1;
    padding: 0;
    margin: 0;
    border: 0;
    color: inherit;
    border-radius: 3px;
    font-size: 12px;
    font-weight: 400;
    cursor: pointer;
    color: #2275d7;
    padding: 7px 6px;
    margin-left: -6px;
}

.uppy-DashboardContent-back:focus {
    outline: none;
}

.uppy-DashboardContent-back::-moz-focus-inner {
    border: 0;
}

.uppy-DashboardContent-back:hover {
    color: #1b5dab;
}

.uppy-DashboardContent-back:focus {
    background-color: #eceef2;
}

.uppy-size--md .uppy-DashboardContent-back {
    font-size: 14px;
}

.uppy-DashboardContent-addMore {
    background: none;
    -webkit-appearance: none;
    font-family: inherit;
    font-size: inherit;
    line-height: 1;
    padding: 0;
    margin: 0;
    border: 0;
    color: inherit;
    border-radius: 3px;
    font-weight: 500;
    cursor: pointer;
    color: #2275d7;
    width: 29px;
    height: 29px;
    padding: 7px 8px;
    margin-right: -5px;
}

.uppy-DashboardContent-addMore:focus {
    outline: none;
}

.uppy-DashboardContent-addMore::-moz-focus-inner {
    border: 0;
}

.uppy-DashboardContent-addMore:hover {
    color: #1b5dab;
}

.uppy-DashboardContent-addMore:focus {
    background-color: #eceef2;
}

.uppy-size--md .uppy-DashboardContent-addMore {
    font-size: 14px;
    width: auto;
    height: auto;
    margin-right: -8px;
}

.uppy-DashboardContent-addMore svg {
    vertical-align: baseline;
    margin-right: 4px;
}

.uppy-size--md .uppy-DashboardContent-addMore svg {
    width: 11px;
    height: 11px;
}

.uppy-DashboardContent-addMoreCaption {
    display: none;
}

.uppy-size--md .uppy-DashboardContent-addMoreCaption {
    display: inline;
}

.uppy-DashboardContent-panel {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: whitesmoke;
    overflow: hidden;
    z-index: 1005;
    border-radius: 5px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-flex: 1 1;
            flex: 1 1;
}

.uppy-Dashboard-AddFilesPanel {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #fafafa;
    background: linear-gradient(0deg, #fafafa 35%, rgba(250, 250, 250, 0.85) 100%);
    box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.15);
    overflow: hidden;
    z-index: 1005;
    border-radius: 5px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.uppy-Dashboard--isAddFilesPanelVisible .uppy-Dashboard-files {
    -webkit-filter: blur(2px);
            filter: blur(2px);
}

.uppy-Dashboard-progress {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 12%;
}

.uppy-Dashboard-progressBarContainer.is-active {
    z-index: 1004;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.uppy-Dashboard-filesContainer {
    position: relative;
    overflow-y: hidden;
    margin: 0;
    -webkit-flex: 1 1;
            flex: 1 1;
}

.uppy-Dashboard-filesContainer:after {
    content: '';
    display: table;
    clear: both;
}

.uppy-Dashboard-files {
    margin: 0;
    padding: 0 0 10px 0;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    -webkit-flex: 1 1;
            flex: 1 1;
}

.uppy-size--md .uppy-Dashboard-files {
    padding-top: 10px;
}

.uppy-Dashboard-dropFilesHereHint {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    visibility: hidden;
    position: absolute;
    top: 7px;
    right: 7px;
    bottom: 7px;
    left: 7px;
    padding-top: 90px;
    border: 1px dashed #2275d7;
    border-radius: 3px;
    z-index: 2000;
    text-align: center;
    background-image: url("data:image/svg+xml,%3Csvg width='48' height='48' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M24 1v1C11.85 2 2 11.85 2 24s9.85 22 22 22 22-9.85 22-22S36.15 2 24 2V1zm0 0V0c13.254 0 24 10.746 24 24S37.254 48 24 48 0 37.254 0 24 10.746 0 24 0v1zm7.707 19.293a.999.999 0 1 1-1.414 1.414L25 16.414V34a1 1 0 1 1-2 0V16.414l-5.293 5.293a.999.999 0 1 1-1.414-1.414l7-7a.999.999 0 0 1 1.414 0l7 7z' fill='%232275D7' fill-rule='nonzero'/%3E%3C/svg%3E");
    background-position: 50% 50%;
    background-repeat: no-repeat;
    color: #707070;
    font-size: 16px;
}

.uppy-Dashboard.uppy-Dashboard--isDraggingOver .uppy-Dashboard-dropFilesHereHint {
    visibility: visible;
}

.uppy-Dashboard.uppy-Dashboard--isDraggingOver .uppy-DashboardContent-bar,
.uppy-Dashboard.uppy-Dashboard--isDraggingOver .uppy-Dashboard-files,
.uppy-Dashboard.uppy-Dashboard--isDraggingOver .uppy-Dashboard-progressindicators {
    opacity: 0.15;
}

.uppy-Dashboard.uppy-Dashboard--isDraggingOver .uppy-DashboardAddFiles {
    opacity: 0.03;
}

.uppy-Dashboard-dropFilesTitle {
    max-width: 300px;
    text-align: center;
    font-size: 16px;
    line-height: 1.35;
    font-weight: 400;
    color: #525252;
    margin: auto;
    padding: 0 15px;
}

.uppy-size--md .uppy-Dashboard-dropFilesTitle {
    max-width: 470px;
    font-size: 27px;
}

.uppy-Dashboard-note {
    font-size: 14px;
    line-height: 1.25;
    text-align: center;
    color: #757575;
    max-width: 350px;
    margin: auto;
    padding: 0 15px;
}

.uppy-size--md .uppy-Dashboard-note {
    font-size: 16px;
    line-height: 1.35;
    max-width: 600px;
}

a.uppy-Dashboard-poweredBy {
    display: inline-block;
    text-align: center;
    font-size: 11px;
    color: #939393;
    text-decoration: none;
    margin-top: 8px;
}

.uppy-Dashboard-poweredByIcon {
    stroke: #939393;
    fill: none;
    margin-left: 1px;
    margin-right: 1px;
    position: relative;
    top: 1px;
    opacity: 0.9;
    vertical-align: text-top;
}

.uppy-DashboardItem-previewIcon {
    width: 25px;
    height: 25px;
    z-index: 100;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

.uppy-size--md .uppy-DashboardItem-previewIcon {
    width: 38px;
    height: 38px;
}

.uppy-DashboardItem-previewIcon svg {
    width: 100%;
    height: 100%;
}

.uppy-DashboardItem-previewIconWrap {
    height: 76px;
    max-height: 75%;
    position: relative;
}

.uppy-DashboardItem-previewIconBg {
    width: 100%;
    height: 100%;
    -webkit-filter: drop-shadow(rgba(0, 0, 0, 0.1) 0px 1px 1px);
            filter: drop-shadow(rgba(0, 0, 0, 0.1) 0px 1px 1px);
}

.uppy-Dashboard-upload {
    position: relative;
    width: 50px;
    height: 50px;
}

.uppy-size--md .uppy-Dashboard-upload {
    width: 60px;
    height: 60px;
}

.uppy-Dashboard-upload .UppyIcon {
    position: relative;
    top: 1px;
    width: 50%;
}

.uppy-Dashboard-uploadCount {
    position: absolute;
    top: -12px;
    right: -12px;
    background-color: #1bb240;
    color: #fff;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    line-height: 16px;
    font-size: 8px;
}

.uppy-size--md .uppy-Dashboard-uploadCount {
    width: 18px;
    height: 18px;
    line-height: 18px;
    font-size: 9px;
}


/* ------------------------------------------------
    [4] Custom Styles
  -------------------------------------------------*/

.uppy-DashboardContent-panel {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;
    z-index: 1005;
    border-radius: 5px;
    display: -webkit-flex;
    display: flex;
}

.uppy-DashboardContent-panelBody {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex: 1 1;
            flex: 1 1;
}

.uppy-Webcam-container {
    width: 100%;
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.uppy-Webcam-videoContainer {
    width: 100%;
    -webkit-flex: 1 1;
            flex: 1 1;
    -webkit-flex-grow: 1;
            flex-grow: 1;
    overflow: hidden;
    background-color: #333;
    text-align: center;
    position: relative;
}

.uppy-Webcam-video {
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
}

.uppy-Webcam-buttonContainer {
    width: 100%;
    height: 75px;
    border-top: 1px solid #eaeaea;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    padding: 0 20px;
}

.uppy-Webcam-button {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    background-color: #e32437;
    color: #fff;
    cursor: pointer;
    transition: all .3s;
}

.uppy-Webcam-button:not(:last-child) {
    margin-right: 12px;
}

.uppy-size--md .uppy-Webcam-button {
    width: 60px;
    height: 60px;
}

.uppy-c-btn:not(:disabled):not(.disabled) {
    cursor: pointer;
}

.uppy-Webcam-button svg {
    width: 30px;
    height: 30px;
    max-width: 100%;
    max-height: 100%;
    display: inline-block;
    vertical-align: text-top;
    overflow: hidden;
    fill: currentColor;
}

.uppy-Webcam-permissons {
    padding: 15px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-flow: column wrap;
            flex-flow: column wrap;
    height: 100%;
    -webkit-flex: 1 1;
            flex: 1 1;
}

.uppy-Webcam-title {
    font-size: 22px;
    line-height: 1.35;
    font-weight: 400;
    margin: 0 0 5px;
    padding: 0 15px;
    max-width: 500px;
    text-align: center;
    color: #333;
}

.uppy-Webcam-permissons p {
    max-width: 450px;
    line-height: 1.3;
    text-align: center;
    line-height: 1.45;
    color: #939393;
    margin: 0;
}
body {
    margin: 0;
    font-family: 'Poppins';
}

@-webkit-keyframes float {
    0% {
        -webkit-transform: scale(1);
                transform: scale(1);
    }

    50% {
        -webkit-transform: scale(1.2);
                transform: scale(1.2);
    }

    100% {
        -webkit-transform: scale(1);
                transform: scale(1);
    }
}

@keyframes float {
    0% {
        -webkit-transform: scale(1);
                transform: scale(1);
    }

    50% {
        -webkit-transform: scale(1.2);
                transform: scale(1.2);
    }

    100% {
        -webkit-transform: scale(1);
                transform: scale(1);
    }
}

.bubble-1 {
    position: absolute;
    top: 0;
    left: 0;
    -webkit-animation: 12s ease-in-out 0s infinite normal none running float;
            animation: 12s ease-in-out 0s infinite normal none running float;
    z-index: 0;
}
.bubble-1 img {
    width: 80vw;
    height: auto;
}
@media screen and (max-width: 1280px) {
    .bubble-1 img {
        width: 100vw;
    }
}

.bubble-2 {
    position: absolute;
    bottom: 0;
    left: 0;
    -webkit-animation: 14s ease-in-out 0s infinite normal none running float;
            animation: 14s ease-in-out 0s infinite normal none running float;
    z-index: 0;
}
.bubble-2 img {
    width: 80vw;
    height: auto;
}
@media screen and (max-width: 1280px) {
    .bubble-2 img {
        width: 100vw;
    }
}

.bubble-3 {
    position: absolute;
    top: 0;
    right: 0;
    -webkit-animation: 16s ease-in-out 0s infinite normal none running float;
            animation: 16s ease-in-out 0s infinite normal none running float;
    z-index: 0;
}
.bubble-3 img {
    width: 80vw;
    height: auto;
}
@media screen and (max-width: 1280px) {
    .bubble-3 img {
        width: 100vw;
    }
}

.auth-layout { 
    display: -webkit-flex; 
    display: flex;
    position: relative;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    overflow: hidden;
    width: 100vw;
    height: 100vh;
    background: transparent linear-gradient(122deg, #36E5E5 0%, #AA00FF 100%) 0% 0% no-repeat padding-box;
}

.auth-layout .auth-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    height: 100%;
    z-index: 10;
}

.auth-layout .auth-container .login-info-outer-container {
    -webkit-flex-grow: 1;
            flex-grow: 1;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    margin-bottom: 20vh;
}

.auth-layout .app-name-container {
    font-size: 60px;
    line-height: 90px;
    letter-spacing: 0.06px;
    font-weight: 600;
    color: #FFFFFF;
    margin-top: 45px;
}
@media screen and (max-width: 600px) {
    .auth-layout .app-name-container {
        font-size: 48px;
        line-height: 64px;
    }
}
@media screen and (max-width: 400px) {
    .auth-layout .app-name-container {
        font-size: 42px;
        line-height: 56px;
    }
}
@media screen and (max-width: 340px) {
    .auth-layout .app-name-container {
        font-size: 36px;
        line-height: 48px;
    }
}

.auth-layout .auth-container .login-info-container {
    max-width: 358px;
    margin-bottom: 20px;
}
@media screen and (max-width: 375px) {
    .auth-layout .auth-container .login-info-container {
        max-width: 280px;
    }
}

.auth-layout .auth-container .login-info-container .login-info-label {
    font-size: 18px;
    margin-bottom: 20px;
    color: #FFF;
}

.auth-layout .auth-container .login-info-container .login-info-validation {
    display: -webkit-flex;
    display: flex;
    margin-bottom: 4px;
}

.auth-layout .auth-container .login-info-container .login-info-validation .login-info-validation-icon {
    fill: #FFFFFF;
    margin-right: 6px;
}
.auth-layout .auth-container .login-info-container .login-info-validation .login-info-validation-icon.hidden {
    visibility: hidden;
}

.auth-layout .auth-container .login-info-container .login-info-validation .login-info-validation-message {
    font-size: 12px;
    line-height: 18px;
    color: #FFFFFF;
}

.auth-layout .auth-container .login-info-actions {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}
.auth-layout .auth-container .login-info-actions .action-link {
    font-size: 12px;
    line-height: 18px;
    color: #FFFFFF;
    cursor: pointer;
}
.auth-layout .auth-container .login-info-actions .action-button {
    margin-left: auto;
}
.auth-layout .auth-container .login-info-actions .action-button button {
    background: #8084f3;
    color: #FFF;
    min-width: 150px;
    min-height: 45px;
    text-transform: none;
}
.auth-layout .auth-container .login-info-actions .action-button button.Mui-disabled {
    opacity: 0.5;
}

.auth-layout .input {
    margin-bottom: 25px;
}

.auth-layout .input .MuiTextField-root {
    min-height: 38px;
    min-width: 340px;
    background:#FFFFFF5A;
    border-radius: 4px;
    padding: 8px;
    border: 1px solid transparent;
}
@media screen and (max-width: 374px) {
    .auth-layout .input .MuiTextField-root {
        min-width: 280px;
    }
}

.auth-layout .input.password .MuiInputAdornment-root {
    margin-bottom: 8px;
}
.auth-layout .input.password .MuiInputAdornment-root .MuiSvgIcon-root {
    fill: #FFFFFF;
}

.auth-layout .input .MuiTextField-root .MuiInputLabel-root {
    color: #FFFFFF;
    -webkit-transform: translate(14px, 20px) scale(1);
            transform: translate(14px, 20px) scale(1);
}
.auth-layout .input .MuiTextField-root .MuiInputLabel-shrink {
    -webkit-transform: translate(8px, 9.5px) scale(0.75);
            transform: translate(8px, 9.5px) scale(0.75);
}

.auth-layout .input .MuiTextField-root .MuiInputBase-root {
    margin-top: 12px;
}
.auth-layout .input .MuiTextField-root .MuiInputBase-input {
    color: #FFFFFF;
    padding: 8px 0px 0px 0px;
}

.auth-layout .input .MuiTextField-root .MuiInput-underline:before {
    display: none !important;
}
.auth-layout .input .MuiTextField-root .MuiInput-underline:after {
    display: none !important;
}

.auth-layout .login-button {
    margin-left: 16px;
    padding-right: 8px;
    color: #FFFFFF;
    margin-bottom: 12px;
    cursor: pointer;
}

.auth-layout .forgot-password {
    font-size: 12px;
    line-height: 18px;
    color: #FFFFFF;
    text-align: center;
    cursor: pointer;
}

.auth-layout .consent-checkbox {
    margin-top: 18px;    
}
.auth-layout .consent-checkbox .MuiSvgIcon-root {
    width: 18px;
    height: 18px;
    color: #FFFFFF;
}
.auth-layout .consent-checkbox .MuiTypography-root {
    color: #FFFFFF;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    margin-left: 6px;
}
.auth-layout .consent-checkbox a {
    color: #FFFFFF;    
}

.auth-layout .input.error .MuiTextField-root {
    border-color: #d93025;
}
.auth-layout .input.error .MuiInputLabel-root { 
    color: #d93025;
}

.auth-layout .c-error {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    max-width: 340px;
    font-size: 12px;
    color: #d93025;
    margin-top: -10px;
    margin-bottom: 10px;
}

.auth-layout .input.error .c-error {
    margin-top: 4px;
    margin-bottom: 0px;
}

.auth-layout .input.error .c-error .MuiSvgIcon-root {
    width: 16px;
    height: 16px;
    margin-right: 4px;
}

.auth-layout input:-webkit-autofill,
.auth-layout input:-webkit-autofill:hover, 
.auth-layout input:-webkit-autofill:focus
.auth-layout input:-webkit-autofill, 
.auth-layout textarea:-webkit-autofill,
.auth-layout textarea:-webkit-autofill:hover
.auth-layout textarea:-webkit-autofill:focus,
.auth-layout select:-webkit-autofill,
.auth-layout select:-webkit-autofill:hover,
.auth-layout select:-webkit-autofill:focus {
  border: 0;
  -webkit-text-fill-font-size: 16px;
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
  background: -webkit-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(0,174,255,0.04) 50%,rgba(255,255,255,0) 51%,rgba(0,174,255,0.03) 100%);  
}

.auth-layout :-webkit-autofill::first-line {
    font-size: 16px;
    color: #fff;
}
:root {
    --text-multiplier: 1;
    --width-multiplier: 1;
}

@media (min-width: 1981px) {
    :root {
        --text-multiplier: 1;
    }
}

@media (min-width: 3840px) {
    :root {
        --text-multiplier: 1;
        --width-multiplier: 1;
    }
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Poppins', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type='number'] {
    -moz-appearance: textfield;
}

.cursor-pointer {
    cursor: pointer;
}

.d-none {
    display: none;
}

.d-flex {
    display: -webkit-flex;
    display: flex;
}

.align-items-center {
    -webkit-align-items: center;
            align-items: center;
}

.justify-content-end {
    -webkit-justify-content: end;
            justify-content: end;
}

.justify-content-center {
    -webkit-justify-content: center;
            justify-content: center;
}

.justify-content-between {
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.justify-content-around {
    -webkit-justify-content: space-around;
            justify-content: space-around;
}

.flex-direction-column {
    -webkit-flex-direction: column;
            flex-direction: column;
}

.p-0 {
    padding: 0;
}

.p-05 {
    padding: 0.5em;
}

.p-1 {
    padding: 1em;
}

.p-2 {
    padding: 2em;
}

.pr-1 {
    padding-right: 1em;
}

.pr-05 {
    padding-right: 0.5em;
}

.m-0 {
    margin: 0;
}

.m-1 {
    margin: 1em;
}

.mt-2 {
    margin-top: 2em;
}

.mt-1 {
    margin-top: 1em;
}

.mr-05 {
    margin-right: 0.5em;
}

.mb-05 {
    margin-bottom: 0.5em;
}

.mr-1 {
    margin-right: 1em;
}

.mr-2 {
    margin-right: 2em;
}

.mb-1 {
    margin-bottom: 1em;
}

.mb-2 {
    margin-bottom: 2em;
}

.mb-3 {
    margin-bottom: 3em;
}

.ml-auto {
    margin-left: auto;
}

.mr-auto {
    margin-right: auto;
}

.m-2 {
    margin: 2em;
}

.m-3 {
    margin: 3em;
}

.ml-3 {
    margin-left: 3em;
}

.ml-1 {
    margin-left: 1em;
}

.ml-2 {
    margin-left: 2em;
}

.mr-3 {
    margin-right: 3em;
}

.white-space-nowrap {
    white-space: nowrap;
}

.font-weight-600 {
    font-weight: 600;
}

.hrStyles {
    border: 1px solid rgba(33, 33, 33, 0.08);
}

.h-100vh {
    height: 100vh;
}

.text-transform-uppercase {
    text-transform: uppercase;
}

.h-100 {
    height: 100%;
}

.text-center {
    text-align: center;
}

::-webkit-scrollbar-track {
    border-radius: 4px;
    background-color: #c3cad5;
}

::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    background-color: #c3cad5;
}

::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgb(151, 146, 146);
}

.w-100 {
    width: 100%;
}

.text-decoration-none {
    text-decoration: none;
}

.overflow-hidden {
    overflow: hidden;
}

.text-overflow-ellipsis {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.text-overflow-ellipsis-one-line {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

