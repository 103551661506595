body {
    margin: 0;
    font-family: 'Poppins';
}

@keyframes float {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.2);
    }

    100% {
        transform: scale(1);
    }
}

.bubble-1 {
    position: absolute;
    top: 0;
    left: 0;
    animation: 12s ease-in-out 0s infinite normal none running float;
    z-index: 0;
}
.bubble-1 img {
    width: 80vw;
    height: auto;
}
@media screen and (max-width: 1280px) {
    .bubble-1 img {
        width: 100vw;
    }
}

.bubble-2 {
    position: absolute;
    bottom: 0;
    left: 0;
    animation: 14s ease-in-out 0s infinite normal none running float;
    z-index: 0;
}
.bubble-2 img {
    width: 80vw;
    height: auto;
}
@media screen and (max-width: 1280px) {
    .bubble-2 img {
        width: 100vw;
    }
}

.bubble-3 {
    position: absolute;
    top: 0;
    right: 0;
    animation: 16s ease-in-out 0s infinite normal none running float;
    z-index: 0;
}
.bubble-3 img {
    width: 80vw;
    height: auto;
}
@media screen and (max-width: 1280px) {
    .bubble-3 img {
        width: 100vw;
    }
}

.auth-layout { 
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    width: 100vw;
    height: 100vh;
    background: transparent linear-gradient(122deg, #36E5E5 0%, #AA00FF 100%) 0% 0% no-repeat padding-box;
}

.auth-layout .auth-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    z-index: 10;
}

.auth-layout .auth-container .login-info-outer-container {
    flex-grow: 1;
    display: flex;
    align-items: center;
    margin-bottom: 20vh;
}

.auth-layout .app-name-container {
    font-size: 60px;
    line-height: 90px;
    letter-spacing: 0.06px;
    font-weight: 600;
    color: #FFFFFF;
    margin-top: 45px;
}
@media screen and (max-width: 600px) {
    .auth-layout .app-name-container {
        font-size: 48px;
        line-height: 64px;
    }
}
@media screen and (max-width: 400px) {
    .auth-layout .app-name-container {
        font-size: 42px;
        line-height: 56px;
    }
}
@media screen and (max-width: 340px) {
    .auth-layout .app-name-container {
        font-size: 36px;
        line-height: 48px;
    }
}

.auth-layout .auth-container .login-info-container {
    max-width: 358px;
    margin-bottom: 20px;
}
@media screen and (max-width: 375px) {
    .auth-layout .auth-container .login-info-container {
        max-width: 280px;
    }
}

.auth-layout .auth-container .login-info-container .login-info-label {
    font-size: 18px;
    margin-bottom: 20px;
    color: #FFF;
}

.auth-layout .auth-container .login-info-container .login-info-validation {
    display: flex;
    margin-bottom: 4px;
}

.auth-layout .auth-container .login-info-container .login-info-validation .login-info-validation-icon {
    fill: #FFFFFF;
    margin-right: 6px;
}
.auth-layout .auth-container .login-info-container .login-info-validation .login-info-validation-icon.hidden {
    visibility: hidden;
}

.auth-layout .auth-container .login-info-container .login-info-validation .login-info-validation-message {
    font-size: 12px;
    line-height: 18px;
    color: #FFFFFF;
}

.auth-layout .auth-container .login-info-actions {
    display: flex;
    align-items: center;
}
.auth-layout .auth-container .login-info-actions .action-link {
    font-size: 12px;
    line-height: 18px;
    color: #FFFFFF;
    cursor: pointer;
}
.auth-layout .auth-container .login-info-actions .action-button {
    margin-left: auto;
}
.auth-layout .auth-container .login-info-actions .action-button button {
    background: #8084f3;
    color: #FFF;
    min-width: 150px;
    min-height: 45px;
    text-transform: none;
}
.auth-layout .auth-container .login-info-actions .action-button button.Mui-disabled {
    opacity: 0.5;
}

.auth-layout .input {
    margin-bottom: 25px;
}

.auth-layout .input .MuiTextField-root {
    min-height: 38px;
    min-width: 340px;
    background:#FFFFFF5A;
    border-radius: 4px;
    padding: 8px;
    border: 1px solid transparent;
}
@media screen and (max-width: 374px) {
    .auth-layout .input .MuiTextField-root {
        min-width: 280px;
    }
}

.auth-layout .input.password .MuiInputAdornment-root {
    margin-bottom: 8px;
}
.auth-layout .input.password .MuiInputAdornment-root .MuiSvgIcon-root {
    fill: #FFFFFF;
}

.auth-layout .input .MuiTextField-root .MuiInputLabel-root {
    color: #FFFFFF;
    transform: translate(14px, 20px) scale(1);
}
.auth-layout .input .MuiTextField-root .MuiInputLabel-shrink {
    transform: translate(8px, 9.5px) scale(0.75);
}

.auth-layout .input .MuiTextField-root .MuiInputBase-root {
    margin-top: 12px;
}
.auth-layout .input .MuiTextField-root .MuiInputBase-input {
    color: #FFFFFF;
    padding: 8px 0px 0px 0px;
}

.auth-layout .input .MuiTextField-root .MuiInput-underline:before {
    display: none !important;
}
.auth-layout .input .MuiTextField-root .MuiInput-underline:after {
    display: none !important;
}

.auth-layout .login-button {
    margin-left: 16px;
    padding-right: 8px;
    color: #FFFFFF;
    margin-bottom: 12px;
    cursor: pointer;
}

.auth-layout .forgot-password {
    font-size: 12px;
    line-height: 18px;
    color: #FFFFFF;
    text-align: center;
    cursor: pointer;
}

.auth-layout .consent-checkbox {
    margin-top: 18px;    
}
.auth-layout .consent-checkbox .MuiSvgIcon-root {
    width: 18px;
    height: 18px;
    color: #FFFFFF;
}
.auth-layout .consent-checkbox .MuiTypography-root {
    color: #FFFFFF;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    margin-left: 6px;
}
.auth-layout .consent-checkbox a {
    color: #FFFFFF;    
}

.auth-layout .input.error .MuiTextField-root {
    border-color: #d93025;
}
.auth-layout .input.error .MuiInputLabel-root { 
    color: #d93025;
}

.auth-layout .c-error {
    display: flex;
    align-items: center;
    max-width: 340px;
    font-size: 12px;
    color: #d93025;
    margin-top: -10px;
    margin-bottom: 10px;
}

.auth-layout .input.error .c-error {
    margin-top: 4px;
    margin-bottom: 0px;
}

.auth-layout .input.error .c-error .MuiSvgIcon-root {
    width: 16px;
    height: 16px;
    margin-right: 4px;
}

.auth-layout input:-webkit-autofill,
.auth-layout input:-webkit-autofill:hover, 
.auth-layout input:-webkit-autofill:focus
.auth-layout input:-webkit-autofill, 
.auth-layout textarea:-webkit-autofill,
.auth-layout textarea:-webkit-autofill:hover
.auth-layout textarea:-webkit-autofill:focus,
.auth-layout select:-webkit-autofill,
.auth-layout select:-webkit-autofill:hover,
.auth-layout select:-webkit-autofill:focus {
  border: 0;
  -webkit-text-fill-font-size: 16px;
  transition: background-color 5000s ease-in-out 0s;
  background: -webkit-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(0,174,255,0.04) 50%,rgba(255,255,255,0) 51%,rgba(0,174,255,0.03) 100%);  
}

.auth-layout :-webkit-autofill::first-line {
    font-size: 16px;
    color: #fff;
}