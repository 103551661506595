@import url('https://fonts.googleapis.com/css2?family=Saira:wght@600&display=swap');

div.AzureADSignIn-root {
    display: flex;
    justify-content: space-around;
    width: 100%;
    padding-top: 5%;

    button.azureADSignIn {
        width: 80%;
        background: #ffffff;
        color: #5E5E5E;
        font-weight: 600;
        padding-right: 12px;
        min-height: 41px;
        border-radius: unset;
        border: 1px solid #5e5e5e;
        padding: unset;

        &:hover {
            background: #2f2f2f;
            color: #ffffff;
        }

        .MuiButton-label {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .azureADLogo {
                padding-left: 12px;
                padding-right: 12px;
            }

            .labelText {
                text-transform: none;
                padding-right: 25px;
                font-family: 'Segoe UI Regular', sans-serif;
                font-weight: 600;
                font-size: 15px;
            }

            div.loginIcon {
                width: 20px;
                height: 18px;
                margin-right: 5px;
                background-size: contain;
                background-repeat: no-repeat;
            }
        }
    }
}
