@import url('https://fonts.googleapis.com/css2?family=Saira:wght@600&display=swap');

div.AdamosSignIn-root {
    display: flex;
    justify-content: space-around;
    width: 100%;

    button.adamosSignIn {
        margin-top: 20px;
        width: 80%;
        border-radius: 5px;
        padding: 5px;
        background: white;
        color: #008dab;
        -webkit-box-shadow: 0px 3px 8px 2px rgba(0, 0, 0, 0.2);
        box-shadow: 0px 3px 8px 2px rgba(0, 0, 0, 0.2);

        &:hover {
            background: #008dab;
            color: white;

            div.loginIcon {
                background-image: url('Icons/loginWhite.svg') !important;
            }
        }

        .MuiButton-label {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .adamosLogo {
                width: 40px;
                height: 40px;
            }

            .labelText {
                text-transform: none;
                padding-right: 25px;
                font-family: 'Saira', sans-serif;
                font-weight: 600;
                font-size: 14px;
            }

            div.loginIcon {
                width: 20px;
                height: 18px;
                margin-right: 5px;
                background-image: url('Icons/loginBlue.svg');
                background-size: contain;
                background-repeat: no-repeat;
            }
        }
    }
}
